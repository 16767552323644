<template>
  <div>
    <!-- <v-sheet
      v-if="orderName || (isEditMode && !isUndelivered)"
      color="grey lighten-2"
      elevation="0"
      class="py-2 px-4 mb-2"
      rounded
    >
      <div class="d-flex justify-space-between items-start">
        <div v-if="orderName" class="text-body-1 font-weight-medium">{{ orderName }}</div>
        <v-switch
          v-model="showAsRestorder"
          label="Visa endast restorderns nuvarande/blivande orderrader"
          v-if="isEditMode && !isUndelivered"
          class="mt-0 pt-0"
          hide-details
        ></v-switch>
      </div>
    </v-sheet> -->
    <div
      v-if="isEditMode && !isUndelivered"
      class="pb-2 mt-4 mb-2"
      style="border-bottom: 1px solid #ccc;"
    >
      <div class="d-flex justify-space-between items-start">
        <v-switch
          v-model="showAsRestorder"
          label="Visa som restorder"
          v-if="isEditMode && !isUndelivered"
          class="mt-0 pt-0"
          hide-details
        ></v-switch>
      </div>
    </div>
    <div class="pb-2">
      <p class="text-button mb-2">Ny orderrad</p>
      <v-btn-toggle
        v-model="newOrderRowType"
        class="xDenseToggle mb-4"
        color="blue darken-4"
        mandatory
      >
        <v-btn
          v-for="type in newOrderRowTypes"
          :key="type.value"
          :value="type.value"
          text
          small
        >
          {{ type.label }}
        </v-btn>
      </v-btn-toggle>
      <app-auto-complete
        v-if="newOrderRowType == 'product'"
        label="product"
        ref="refNewProductNumber"
        apiEndPoint="/cms/products/autocomplete-number"
        txtLabel="Sök ny produkt att lägga till"
        @onSelection="onProductSelect"
        @onBarcodeScan="onProductBarcodeScan"
        mode="addToList"
        :doFocus="doFocusProductAc"
        :searchableItems="searchableItems_product"
        :searchableItemLabelCols="searchableItemLabelCols_product"
      ></app-auto-complete>
      <v-text-field
        v-model="newOrderRowText"
        ref="refNewOrderRowText"
        v-if="newOrderRowType == 'text'"
        hide-details
        dense
        outlined
        background-color="white"
        placeholder="Ange fritext"
        @keyup.enter="addOrderRowText"
      >
      </v-text-field>
    </div>
    <div
      class="table-order-rows mb-2"
    >
      <div>
        <div>
          <div></div>
          <div v-if="showFortnoxStatusColumn">Fortnox</div>
          <div v-if="hasModule_stock" class="text-left">Lager</div>
          <div class="text-left">Antal</div>
          <div v-if="isEditMode && showRestAndDeliveredQty" class="text-center" title="Varav levererat antal">Lev</div>
          <div v-if="isEditMode && showRestAndDeliveredQty" class="text-center" title="Varav levererat resterande antal">Rest</div>
          <div v-if="isEditMode" class="text-center" title="Justering">Just</div>
          <div class="text-left">Art nr</div>
          <div class="text-left">Benämning</div>
          <div v-if="showOptionColumn" class="text-left">Referens</div>
          <div v-if="hasModule_orderRowMarking" class="text-left">Kundmärkning</div>
          <div class="text-left">áPris</div>
          <div class="text-left">Rabatt</div>
          <div class="text-left">Summa</div>
          <div></div>
        </div>
      </div>
      <draggable
        :list="orderRows"
        @start="drag=true"
        @end="drag=false"
        handle=".handle"
        @update="onOrderRowDrag"
      >
        <div v-for="orderRow, index of orderRows" :key="orderRow.row_id" :class="{ 'alert-row': shouldAlertRow(orderRow), 'faded': orderRow.removeMe, 'row-type-product': isProductOrderRow(orderRow), 'row-type-text': orderRow.row_type === 'text', 'delivered': isDeliveredOrderRow(orderRow), 'hidden': shouldHideOrderRow(orderRow) }">
          <!--drag icon-->
          <div>
            <div class="order-row-responsive-cell-label">Orderrad {{ index + 1 }}</div>
            <v-icon
              class="handle"
            >
              mdi-arrow-up-down
            </v-icon>
          </div>
          <!--fortnox status-->
          <div class="icon" v-if="showFortnoxStatusColumn">
            <v-tooltip
              top
              dark
            >
              <template v-slot:activator="{ on }">
                <v-icon v-if="orderRow.fortnoxStatus === 'success'" v-on="on" color="success">mdi-check-outline</v-icon>
                <v-icon v-if="orderRow.fortnoxStatus === 'warning'" v-on="on" color="warning">mdi-alert-outline</v-icon>
                <v-icon v-if="orderRow.fortnoxStatus === 'error'" v-on="on" color="error">mdi-alert-outline</v-icon>
              </template>
              <span>{{ orderRow.fortnoxComment }}</span>
            </v-tooltip>
          </div>
          <!--stock--->
          <div
            class="text-right only-row-type-product"
            v-if="hasModule_stock"
          >
            <div v-if="isProductOrderRow(orderRow)" class="order-row-responsive-cell-label">Lager</div>
            <template v-if="orderRow.stocks">
              <span class="stocks-table">
                <span v-for="stock of orderRow.stocks" :key="stock.label">
                  <span>{{ stock.text }}:</span>
                  <span class="no-wrap">{{ stock.qty }} st</span>
                </span>
              </span>
            </template>
            <div v-else-if="isProductOrderRow(orderRow)" class="text-caption text-no-wrap">
              {{ orderRow.p_stock }} st
            </div>
          </div>
          <!--quantity-->
          <div
            class="input only-row-type-product"
          >
            <div v-if="isProductOrderRow(orderRow)" class="order-row-responsive-cell-label">Antal</div>
            <v-text-field
              v-model="orderRow.quantity"
              v-if="isProductOrderRow(orderRow)"
              ref="refQty"
              suffix="st"
              hide-details
              dense
              outlined
              class="number responsiveInput_qty"
              type="number"
              :min="isEditMode ? orderRow.quantity_delivered : 0"
              max="9999"
              background-color="white"
              :rules="[ rules.required, rules.positiveInteger(orderRow.quantity, { min: isEditMode ? orderRow.quantity_delivered : 0 }) ]"
              @focus="onFieldFocus(`quantity-${index}`, orderRow.quantity)"
              @blur="onFieldEdit('orderRow', 'quantity', orderRow)"
              @keyup="orderRow.quantity = restoreInteger(orderRow.quantity, { min: isEditMode ? orderRow.quantity_delivered : 0 })"
              @keyup.enter="onOrderRowEnter"
            >
            </v-text-field>
          </div>
          <!--quantity delivered-->
          <div v-if="isEditMode && showRestAndDeliveredQty" class="text-right only-row-type-product">
            <div v-if="isProductOrderRow(orderRow)" class="order-row-responsive-cell-label" title="Varav levererat antal">Levererat antal</div>
            <v-chip v-if="isProductOrderRow(orderRow) && !isNewOrderRow(orderRow)">{{ orderRow.quantity_delivered }} st</v-chip>
          </div>
          <!--quantity rest-->
          <div v-if="isEditMode && showRestAndDeliveredQty" class="text-right only-row-type-product">
            <div v-if="isProductOrderRow(orderRow)" class="order-row-responsive-cell-label" title="Varav resterande antal">Resterande antal</div>
            <v-chip v-if="isProductOrderRow(orderRow) && !isNewOrderRow(orderRow)" :color="orderRow.quantity - orderRow.quantity_delivered === 0 ? 'default' : 'info'">{{ orderRow.quantity - orderRow.quantity_delivered }} st</v-chip>
          </div>
          <!--quantity adjustment-->
          <div v-if="isEditMode" class="text-right only-row-type-product">
            <div v-if="isProductOrderRow(orderRow)" class="order-row-responsive-cell-label" title="Justerat antal">Justerat antal</div>
            <v-chip v-if="isProductOrderRow(orderRow) && !isNewOrderRow(orderRow)" :color="orderRow.quantity > orderRow.quantity_orw ? 'success' : orderRow.quantity < orderRow.quantity_orw ? 'error' : 'default'"><span style="line-height: 0;" v-if="orderRow.quantity > orderRow.quantity_orw">+</span>{{ orderRow.quantity - orderRow.quantity_orw }} st</v-chip>
          </div>
          <!--product number-->
          <div class="only-row-type-product">
            <div v-if="isProductOrderRow(orderRow)" class="order-row-responsive-cell-label">Art nr</div>
            <template v-if="isProductOrderRow(orderRow)">
              {{ orderRow.p_number }}
            </template>
          </div>
          <!--product name-->
          <div class="order-row-name">
            <div class="order-row-responsive-cell-label">Benämning</div>
            <div class="order-row-name-inner">
              <v-tooltip
                left
                dark
                v-if="shouldAlertRow(orderRow)"
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="red" class="pr-2">mdi-cancel</v-icon>
                </template>
                <span>{{ composeAlert(orderRow) }}</span>
              </v-tooltip>
              <v-text-field
                v-model="orderRow.name"
                hide-details
                dense
                outlined
                background-color="white"
                @focus="onFieldFocus(`name-${index}`, orderRow.name)"
                @blur="onFieldEdit('orderRow', 'name', orderRow)"
              >
              </v-text-field>
            </div>
          </div>
          <!--option-->
          <div
            class="input only-row-type-product"
            v-if="showOptionColumn && isProductOrderRow(orderRow)"
          >
            <div class="order-row-responsive-cell-label">Referens</div>
            <v-text-field
              v-model="orderRow.option"
              hide-details
              dense
              outlined
              style="font-size:12px;"
              background-color="white"
              @focus="onFieldFocus(`option-${index}`, orderRow.option)"
              @blur="onFieldEdit('orderRow', 'option', orderRow)"
            >
            </v-text-field>
          </div>
          <!--marking-->
          <div
            class="input only-row-type-product"
            v-if="hasModule_orderRowMarking && isProductOrderRow(orderRow)"
          >
            <div class="order-row-responsive-cell-label">Kundmärkning</div>
            <v-text-field
              v-model="orderRow.marking"
              hide-details
              dense
              outlined
              style="font-size:12px;"
              background-color="white"
              @focus="onFieldFocus(`marking-${index}`, orderRow.marking)"
              @blur="onFieldEdit('orderRow', 'marking', orderRow)"
              maxlength="25"
            >
            </v-text-field>
          </div>
          <!--aPrice-->
          <div
            class="input only-row-type-product"
          >
            <div v-if="isProductOrderRow(orderRow)" class="order-row-responsive-cell-label">áPris</div>
            <v-text-field
              v-model="orderRow.price"
              v-if="isProductOrderRow(orderRow)"
              suffix="kr"
              hide-details
              dense
              outlined
              class="amount responsiveInput_aPrice"
              maxlength="9"
              background-color="white"
              :rules="[ rules.required ]"
              @focus="onFieldFocus(`price-${index}`, orderRow.price)"
              @blur="onFieldEdit('orderRow', 'price', orderRow)"
              @keyup="orderRow.price = restoreNumeric(orderRow.price)"
              @keyup.enter="onOrderRowEnter"
              :disabled="isEditMode && orderRow.quantity_delivered > 0"
            >
            </v-text-field>
          </div>
          <!--discountPercent-->
          <div
            class="input only-row-type-product"
          >
            <div v-if="isProductOrderRow(orderRow)" class="order-row-responsive-cell-label">Rabatt</div>
            <v-text-field
              v-model="orderRow.discount_percent"
              v-if="isProductOrderRow(orderRow)"
              suffix="%"
              hide-details
              dense
              outlined
              class="number size-3"
              type="number"
              max="100"
              min="0"
              background-color="white"
              :rules="[ rules.required ]"
              @focus="onFieldFocus(`discount_percent-${index}`, orderRow.discount_percent)"
              @blur="onFieldEdit('orderRow', 'discount_percent', orderRow)"
              @keyup="orderRow.discount_percent = restoreInteger(orderRow.discount_percent, { min: 0, max: 100 })"
              @keyup.enter="onOrderRowEnter"
              :disabled="isEditMode && orderRow.quantity_delivered > 0"
            >
            </v-text-field>
          </div>
          <!--total-->
          <div
            class="text-right font-weight-medium text-no-wrap only-row-type-product"
          >
            <div v-if="isProductOrderRow(orderRow)" class="order-row-responsive-cell-label">Summa</div>
            <template v-if="isProductOrderRow(orderRow)">
              {{ formatOrderRowTotal(orderRow) }}
            </template>
          </div>
          <!--remove icon-->
          <div>
            <div class="order-row-responsive-cell-label"></div>
            <v-icon
              v-if="!orderRow.removeMe && (!isEditMode || orderRow.quantity_delivered == 0)"
              @click="removeOrderRow(orderRow)"
              color="red"
              tabindex="-1"
            >
              mdi-trash-can-outline
            </v-icon>
            <v-icon
              v-if="orderRow.removeMe"
              @click="unremoveOrderRow(orderRow)"
              color="black"
            >
              mdi-delete-restore
            </v-icon>
          </div>
        </div>
      </draggable>
    </div>
    <div class="text-right text-body-1 font-weight-medium text-no-wrap pa-2">
      Exkl. moms: {{ orderTotalExclVat }}
    </div>
    <div class="text-right text-body-1 font-weight-medium text-no-wrap pa-2">
      Inkl. moms: {{ orderTotalInclVat }}
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import AutoComplete from './AutoComplete.vue'
import draggable from 'vuedraggable'
import useFinance from '../helpers/finance'
import useValidation from '../helpers/validation'
// import { useI18n } from 'vue-i18n'

export default {
  name: 'OrderRows',
  components: {
    'app-auto-complete': AutoComplete,
    draggable,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    orderRows: {
      type: Array,
      required: true,
    },
    searchableProduct_items: {
      type: Array,
      required: false,
    },
    searchableProduct_labelCols: {
      type: Array,
      required: false,
    },
    orderRowIdToFocus: {
      type: Number,
      required: true,
    },
    priceTypeIsNetto: {
      type: Boolean,
      required: true,
    },
    isUndelivered: {
      type: Boolean,
      required: false,
    },
    showOptionColumn: {
      type: Boolean,
      required: false,
    }
  },
  setup(props, context) {
    // Vue 2
    const store = require('@/store/store').default;
    const loggedInData = computed(() => store.getters.loggedInData);

    const isKaxig = loggedInData.value.isKaxig;

    const { rules, restoreNumeric, restoreInteger } = useValidation();
    const { getBruttoOfNetto, getNettoOfBrutto } = useFinance();

    const newOrderRowType = ref(null);
    const doFocusProductAc = ref(false);
    const newOrderRowText = ref('');
    const refNewOrderRowText = ref(null);
    const refNewProductNumber = ref(null);
    const refQty = ref([]);
    const showAsRestorder = ref(false);

    const hasModule_orderRowMarking= computed(() => {
      return store.getters.hasKobraModule('orderRowMarking');
    });

    const showFortnoxStatusColumn = props.mode === 'edit-fortnox' && isKaxig;
    const isEditMode = props.mode.split('-')[0] === 'edit';
    const showRestAndDeliveredQty = isEditMode && props.isUndelivered === false;

    console.log("isEditMode", isEditMode, props.mode);

    const ORDER_ROW_TYPE = {
      PRODUCT: 'product',
      TEXT: 'text',
    }
    
    const newOrderRowTypes = [
      {
        label: 'Vara',
        value: ORDER_ROW_TYPE.PRODUCT,
      },
      {
        label: 'Fritext',
        value: ORDER_ROW_TYPE.TEXT,
      },
    ];

    // emits
    const onProductSelect = product => {
      context.emit('onProductSelect', product);
    }

    const onProductBarcodeScan = barcode => {
      context.emit('onProductBarcodeScan', barcode);
    }

    const onOrderRowDrag = () => {
      context.emit('onOrderRowDrag', props.orderRows);
    }

    const addOrderRowText = () => {
      context.emit('addOrderRowText', newOrderRowText.value);
      newOrderRowText.value = '';
    }

    const onFieldEdit = (mode, label, data, alwaysUpdate = false) => {
      context.emit('onFieldEdit', mode, label, data, alwaysUpdate);
    }

    const onFieldFocus = (label, value) => {
      context.emit('onFieldFocus', label, value);
    }

    const removeOrderRow = orderRow => {
      context.emit('removeOrderRow', orderRow);
    }

    const unremoveOrderRow = orderRow => {
      context.emit('unremoveOrderRow', orderRow);
    }

    // computed
    const hasModule_stock = computed(() => {
      return store.getters.hasKobraModule('stock');
    });

    const shouldIncludeOrderRowInTotal = orderRow => {
      return isProductOrderRow(orderRow) && !shouldHideOrderRow(orderRow) && !orderRow.removeMe;
    }

    const orderTotalInclVat = computed(() => {
      const total = props.orderRows
        .filter(orderRow => shouldIncludeOrderRowInTotal(orderRow))
        .reduce((result, orderRow) => {
          let amount = getOrderRowTotal(orderRow);
          if (props.priceTypeIsNetto) {
            // add vat
            amount = getBruttoOfNetto(amount, orderRow.vat_percent);
          }
          return result + amount;
        }, 0);
      return formatAmount(total);
    });

    const orderTotalExclVat = computed(() => {
      const total = props.orderRows
        .filter(orderRow => shouldIncludeOrderRowInTotal(orderRow))
        .reduce((result, orderRow) => {
          let amount = getOrderRowTotal(orderRow);
          if (!props.priceTypeIsNetto) {
            // subtract vat
            amount = getNettoOfBrutto(amount, orderRow.vat_percent);
          }
          return result + amount;
        }, 0);
      return formatAmount(total);
    });

    // watch
    watch(() => props.orderRowIdToFocus, newValue => {
      if (newValue !== 0) {
        // focus on qty of order row id
        let refIndex = -1;
        for (let i = 0; i < props.orderRows.length; i++) {
          if (isProductOrderRow(props.orderRows[i])) {
            // only count if product, cause otherwise the qty field is not rendered
            refIndex++;
            if (props.orderRows[i].row_id == newValue) {
              break;
            }
          }
        }
        if (refIndex !== -1) {
          // focus in a little while to avoid blur/focus conflicts
          setTimeout(() => {
            refQty.value[refIndex].$refs.input.focus();
          }, 250);
        }
      }
    });

    watch(() => newOrderRowType.value, newValue => {
      setTimeout(() => {
        switch (newValue) {
          case ORDER_ROW_TYPE.TEXT:
            refNewOrderRowText.value.focus();
            break;
          case ORDER_ROW_TYPE.PRODUCT:
            setFocusProductAc();
            break;
        }
      }, 250);
    });

    // functions
    const shouldAlertRow = orderRow => {
      return orderRow.expired || orderRow.inactive || orderRow.not_stock_goods;
    }

    const composeAlert = orderRow => {
      if (orderRow.inactive) {
        return "Inaktiv artikel";
      }
      if (orderRow.expired) {
        return "Utgången artikel";
      }
      if (orderRow.not_stock_goods) {
        return "Ej lagervara";
      }
      return '';
    }

    const onOrderRowEnter = () => {
      setFocusProductAc();
    }

    const setFocusProductAc = () => {
      doFocusProductAc.value = true;
      // we have to switch back in order for the prop to detect next true-flag
      setTimeout(() => {
        doFocusProductAc.value = false;
      }, 100);  
    }  

    const getOrderRowTotal = orderRow => {
      // if show rest order, we should use "rest qty"
      const qty = showAsRestorder.value ? orderRow.quantity - orderRow.quantity_delivered : orderRow.quantity;
      return applyDiscount(orderRow.price * qty, orderRow.discount_percent);
    }  

    const formatOrderRowTotal = orderRow => {
      return formatAmount(getOrderRowTotal(orderRow));
    }

    const applyDiscount = (amount, discountPercent) => {
      return +amount * (1 - discountPercent / 100);
    }
    
    const formatAmount = amount => {
      return amount.toFixed(2) + ' kr';
    }

    const isProductOrderRow = orderRow => {
      return orderRow.row_type === 'product';
    }

    const isDeliveredOrderRow = orderRow => {
      return isEditMode && isProductOrderRow(orderRow) && !orderRow.OrderRow && !isNewOrderRow(orderRow) && orderRow.quantity - orderRow.quantity_delivered === 0;
    }

    const isNewOrderRow = orderRow => {
      return orderRow.row_id < 0;
    }

    const shouldHideOrderRow = orderRow => {
      // if showAsRestorder is checked - only show if in the rest order or if adjusted qty is above 0
      return showAsRestorder.value && !isNewOrderRow(orderRow) && !orderRow.OrderRow && orderRow.quantity - orderRow.quantity_orw <= 0;
    }

    return {
      newOrderRowType,
      newOrderRowTypes,
      onProductSelect,
      onProductBarcodeScan,
      onOrderRowDrag,
      addOrderRowText,
      doFocusProductAc,
      onFieldEdit,
      onFieldFocus,
      onOrderRowEnter,
      refQty,
      searchableItems_product: props.searchableProduct_items,
      searchableItemLabelCols_product: props.searchableProduct_labelCols,
      newOrderRowText,
      orderTotalInclVat,
      orderTotalExclVat,
      formatOrderRowTotal,
      rules,
      restoreNumeric,
      restoreInteger,
      hasModule_stock,
      removeOrderRow,
      unremoveOrderRow,
      shouldAlertRow,
      composeAlert,
      showFortnoxStatusColumn,
      refNewOrderRowText,
      refNewProductNumber,
      isEditMode,
      showRestAndDeliveredQty,
      showAsRestorder,
      isDeliveredOrderRow,
      shouldHideOrderRow,
      isNewOrderRow,
      isProductOrderRow,
      hasModule_orderRowMarking,
    }
  }
}
</script>

<style scoped>
.alert-row {
  background-color:lightpink;
}
.faded {
  opacity: 0.5;
}
.v-data-table {
  background-color: transparent !important;
}
/* in order to have autocomplete suggestion div visible when inside table */
.v-data-table__wrapper {
  overflow: visible !important;
}
span.stocks-table {
  display: table;
}
span.stocks-table > span {
  display: table-row;
}
span.stocks-table > span > span {
  display: table-cell;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
}
span.stocks-table > span > span:nth-of-type(1) {
  padding-right: 6px;
}
div.table-order-rows {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
div.table-order-rows > div {
  display: table-row-group;
}
div.table-order-rows > div:nth-of-type(1) > div > div {
  font-weight: bold;
  font-size: 12px;
  border-bottom:1px solid #bbb;
}
div.table-order-rows > div > div {
  display: table-row;
}
div.table-order-rows > div > div > div {
  display: table-cell;
  vertical-align: middle;
  padding: 8px 6px;
}
div.table-order-rows > div:nth-of-type(2) > div > div {
  border-bottom:1px solid #bbb;
}
div.table-order-rows div.input {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
div.table-order-rows div.delivered > div {
  background-color:rgba(0, 0, 0, 0.05);
}
div.table-order-rows div.icon {
  padding-left: 8px !important;
}
.order-row-responsive-cell-label {
  display: none;
  font-weight: bold;
  font-size: 12px;
  padding-left: 8px;
}
.responsiveInput_qty {
  width: 94px;
}
.responsiveInput_aPrice {
  width: 120px;
}
div.order-row-name-inner {
  display: flex;
  align-items: center;
  gap: 2px;
}
@media (min-width:750.0001px) {
  div.table-order-rows div.row-type-text div.order-row-name {
    position: relative;
    overflow: visible;
    min-width: 240px;
  }
  div.table-order-rows div.row-type-text div.order-row-name input {
    min-width: 240px;
  }
}
div.hidden {
  display: none !important;
}
@media (max-width:750px) {
  div.table-order-rows {
    display: block;
  }
  div.table-order-rows > div:first-child {
    display: none !important;
  }
  div.table-order-rows > div {
    display: block;
  }
  div.table-order-rows > div > div {
    display: block;
    border-top: 1px solid black;
    margin-bottom: 20px;
  }
  div.table-order-rows > div > div > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  div.table-order-rows > div > div > div:first-child {
    background-color: rgba(87, 179, 255, 0.255);
  }
  div.table-order-rows > div > div > div:last-child {
    border-bottom-width:0 !important;
  }
  div.table-order-rows > div > div > div > * {
    flex-grow: 0;
  }
  .order-row-responsive-cell-label {
    display: block;
  }
  div.table-order-rows div.row-type-text div.only-row-type-product {
    display: none !important;
  }
}
</style>